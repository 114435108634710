<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Formulario de edición de Rol {{ role.name }}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="role.name"
                label="Nombre del Rol"
                :rules="
                  rules.concat(
                    (v) => (v && v.length <= 100) || 'Máximo 100 caracteres'
                  )
                "
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="permissionGestorSeguridad"
                :items="itemsGestorSeguridad"
                :item-text="'value'"
                :item-value="'value'"
                attach
                chips
                label="Permisos Gestor de Seguridad"
                multiple
                outlined
              ></v-select>

              <v-select
                v-model="permissionGestorCarga"
                :items="itemsGestorCarga"
                :item-text="'value'"
                :item-value="'value'"
                attach
                chips
                label="Permisos Gestor de Carga y consolidacion Archivos"
                multiple
                outlined
              ></v-select>

              <v-select
                v-model="permissionGestorCalidad"
                :items="itemsGestorCalidad"
                :item-text="'value'"
                :item-value="'value'"
                attach
                chips
                label="Permisos Gestor de Calidad"
                multiple
                outlined
              ></v-select>

              <v-select
                v-model="permissionConciliador"
                :items="itemsConciliador"
                :item-text="'value'"
                :item-value="'value'"
                attach
                chips
                label="Permisos Conciliador"
                multiple
                outlined
              ></v-select>

              <v-select
                v-model="permissionAutogestor"
                :items="itemsAutogestor"
                :item-text="'value'"
                :item-value="'value'"
                attach
                chips
                label="Permisos Autogestor"
                multiple
                outlined
              ></v-select>

              <v-select
                v-model="permissionRepositorioFotografico"
                :items="itemsRepositorioFotografico"
                :item-text="'value'"
                :item-value="'value'"
                attach
                chips
                label="Permisos Repositorio Fotografico"
                multiple
                outlined
              ></v-select>

              <v-select
                v-model="permissionReportes"
                :items="itemsReportes"
                :item-text="'value'"
                :item-value="'value'"
                attach
                chips
                label="Permisos Reportes"
                multiple
                outlined
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RoleService from "@/services/role.service"
import validationField from "@/util/validationField"

export default {
  name: "Calidad",
  data() {
    return {
      role: {
        name: undefined,
        permissions: [],
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      itemsGestorSeguridad: [
        { id: 101, value: "Gestión de Usuarios" },
        { id: 102, value: "Gestión de Roles" },
      ],
      permissionGestorSeguridad: [],
      itemsGestorCarga: [
        { id: 201, value: "Definición de layouts" },
        { id: 202, value: "Consulta Relaciones" },
        { id: 203, value: "Edicion Relaciones" },
        { id: 204, value: "Consulta Consolidacion Archivos" },
        { id: 205, value: "Edicion Consolidacion Archivos" },
        { id: 206, value: "Ejecutar Proceso Consolidacion" }
      ],
      permissionGestorCarga: [],
      itemsGestorCalidad: [
        { id: 301, value: "Definición de variables" },
        { id: 302, value: "Definición de reglas de calidad" },
        { id: 303, value: "Definición de proceso de calidad" },
        { id: 304, value: "Ejecución de proceso de calidad" },
        { id: 305, value: "Consultar log de ejecuciones" },
        
      ],
      permissionGestorCalidad: [],
      itemsReportes: [
        { id: 401, value: "COMDAC" },
        { id: 402, value: "Desincorporación" },
        { id: 403, value: "Taxonomía de activos fijos" },
        { id: 404, value: "Panoramico de activos fijos" },
        {
          id: 405,
          value: "Panorama gestión validacion de inventarios op. asociadas",
        },
        { id: 406, value: "Atención de solicitudes 2021" },
        { id: 407, value: "Informe gestor consolidado de solicitudes" },
        { id: 408, value: "Diagnóstico de calidad" },
        { id: 409, value: "Incorporaciones" },
        { id: 410, value: "Métricas del sistema" },
        { id: 411, value: "Reporte Vitrina" },
      ],
      permissionReportes: [],
      itemsConciliador: [
        { id: 501, value: "Definición de reglas de conciliador" },
        { id: 502, value: "Definición de proceso de conciliador" },
        { id: 503, value: "Ejecución de proceso de conciliador" }
      ],
      permissionConciliador: [],
      itemsAutogestor: [
        { id: 601, value: "Autogestor - Definición de consulta" },
        { id: 602, value: "Autogestor - Consulta" }
      ],
      permissionAutogestor: [],
      itemsRepositorioFotografico: [
        { id: 701, value: "Repositorio Fotográfico - Consultar" },
        { id: 702, value: "Repositorio Fotográfico - Cargar" },
        { id: 703, value: "Repositorio Fotográfico - Trasladar" },
        { id: 704, value: "Repositorio Fotográfico - Vitrina" },
        { id: 705, value: "Eliminar Carga Registro Fotográfico" },
        { id: 706, value: "Repositorio Fotográfico - Análisis" },
        
      ],
      permissionRepositorioFotografico: [],
      loadingSave: false,
      dialog: false,
    };
  },
  computed: {
    permissions() {
      return this.permissionGestorSeguridad
        .concat(this.permissionGestorCarga)
        .concat(this.permissionGestorCalidad)
        .concat(this.permissionReportes)
        .concat(this.permissionConciliador)
        .concat(this.permissionAutogestor)
        .concat(this.permissionRepositorioFotografico);
    },
  },
  mounted() {
    if (!this.$store.state.permissions.includes("Gestión de Roles"))
      this.$router.push("/dashboard");
    if (localStorage.getItem("datos_rol")) {
      this.role = JSON.parse(localStorage.getItem("datos_rol"));
      this.permissionGestorSeguridad = this.role.permissions
        .filter((x) => Math.round(x.id / 100) === 1)
        .map((x) => x.name);
      this.permissionGestorCarga = this.role.permissions
        .filter((x) => Math.round(x.id / 100) === 2)
        .map((x) => x.name);
      this.permissionGestorCalidad = this.role.permissions
        .filter((x) => Math.round(x.id / 100) === 3)
        .map((x) => x.name);
      this.permissionReportes = this.role.permissions
        .filter((x) => Math.round(x.id / 100) === 4)
        .map((x) => x.name);
      this.permissionConciliador = this.role.permissions
        .filter((x) => Math.round(x.id / 100) === 5)
        .map((x) => x.name);
      this.permissionAutogestor = this.role.permissions
        .filter((x) => Math.round(x.id / 100) === 6)
        .map((x) => x.name);
      this.permissionRepositorioFotografico = this.role.permissions
        .filter((x) => Math.round(x.id / 100) === 7)
        .map((x) => x.name);
    }
  },
  methods: {
    save() {
      this.loadingSave = true;
      this.role.permissions = this.permissions;
      if (this.role.hasOwnProperty("id")) {
        RoleService.editItem(this.role).then(
          (response) => {
            this.$router.push({ path: `/roles` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        RoleService.newItem(this.role).then(
          (response) => {
            this.$router.push({ path: `/roles` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    preCancelar() {
      this.dialog = true;
    },
    goBack() {
      this.$router.push({ path: "/roles" });
    },
  },
};
</script>

<style>
</style>
